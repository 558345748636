<template>
  <v-col>
    <v-row justify="center">
      <v-col>
        <validation-observer ref="observer">
          <validation-provider
            v-slot="{  }"
            vid="answer"
          >
            <v-simple-table
              dense
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th
                      v-for="(column, index) in table.columns"
                      class="text-left"
                      :key="index"
                    >
                      {{ column.title }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, rowIndex) in table.rows"
                    :key="rowIndex"
                  >
                    <td class="title row-title">{{ row.title }}</td>
                    <td
                      v-for="(v, columnIndex) in table.columns.length"
                      :key="columnIndex"
                      class="py-2"
                    >
                      <v-text-field
                        :value="value[question.id] ? value[question.id][`${rowIndex}-${columnIndex}`] : ''"
                        dense
                        flat
                        filled
                        single-line
                        hide-details="auto"
                        @change="update(`${rowIndex}-${columnIndex}`, $event)"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </validation-provider>
        </validation-observer>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

  export default {

    props: {
      value: {
        type: Object,
        default: () => { return {} },
      },
      question: {
        type: Object,
        default: () => { return {} },
      },
      validation: {
        type: Object,
        default: () => { return {} },
      },
    },

    data: () => ({
      table: {}
    }),

    watch: {
      validation (errors) {
        this.$refs.observer.setErrors(errors)
      }
    },

    mounted () {
      this.table = JSON.parse(this.question.schema)
    },

    methods: {
      update(key, value) {
        const allAnswers = { ...this.value }
        allAnswers[this.question.id] = { ...allAnswers[this.question.id], [key]: value }
        this.$emit('input', allAnswers)

        this.$emit('change', value)
      },

    },

  }
</script>

<style scoped>
  table th + th { border-left:1px solid #dddddd; }
  table td + td { border-left:1px solid #dddddd; }
  .row-title { line-height: 1rem !important; }
</style>
